.hop-legend {
  padding: 1%;
  background-color: $form-background;
  border: 1px solid $border-color;
  min-width: 40%;
  max-width: 50%;

  @media (max-width: 1024px) and (min-width: 578px) {
    min-width: 85%;
  }
  @media (max-width: 577px) {
    max-width: 90%;
    min-width: 95%;
  }
}

.hop-org {
  color: #3cab69;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}
.hop-term {
  color: #3cab69;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}
.hop-dorg {
  color: #3cab69;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-dorg-arrow {
  color: #3cab69;
}

.hop-ior {
  color: #3463c3;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-nr {
  color: #de1b40;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-dnr {
  color: #de1b40;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-dnr-arrow {
  color: #de1b40;
}

.hop-nf {
  color: #25a3de;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-dnf {
  color: #25a3de;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-dnf-arrow {
  color: #25a3de;
}

.hop-poe {
  color: #8fdc16;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-xd {
  color: black;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-int {
  color: #7216dc;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-fpd {
  color: #7216dc;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-se {
  color: #abae75;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}

.hop-detail-body {
  padding-left: 5%;
  padding-right: 5%;

  .hop-top-details-list {
    padding: 0;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      &:nth-child(2) {
        .telecom-label {
          text-align: center;
        }
      }
    }
  }

  .telecom-label {
    padding: 0;
  }

  .hop-detail-form {
    background-color: $form-background;
    border: 1px solid $border-color;
    padding: 1.5rem;
    margin: 0 -1.5rem;
    @media (max-width: 1439px) and (min-width: 578px) {
      width: 100%;
    }
    @media (max-width: 577px) {
      width: 110%;
      margin: 0%;
      padding: 0%;
    }
  }

  .hop-detail-form-dark {
    background-color: $form-background-darker;
    border: 1px solid $border-color-darker;
    padding: 1.5rem;
    margin: 0 -1.5rem;
    width: 83.5%;
    @media (max-width: 1439px) and (min-width: 578px) {
      width: 100%;
    }
    @media (max-width: 577px) {
      min-width: 110%;
      margin: 0%;
      padding: 0%;
    }
  }

  .form-label {
    padding-left: 23px;
    padding-right: 10px;
    &.no-padding {
      padding-right: 0;
    }
  }

  .checkmark {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 1px solid gray;
  }

  .checkbox-label {
    font-size: 15px;
  }

  .input-hop {
    font-size: $input-text-fontSize !important;
  }

  .input-error {
    text-align: left;
  }
}

.textarea-hop {
  color: #494949 !important;
  border: 1px solid $border-color;
  padding: 8px 16px;

  &:focus {
    outline: none;
    border: 1px solid $border-focusColor;
    box-shadow: none;
  }
}

.card-body-hop-provider,
.card-body-hop-traceback {
  padding: 0;
}

.card-body-hop {
  padding: 0;

  .hops-datatable {
    &:nth-child(1) {
      max-width: 60px;
      margin-left: 20px;
    }

    &:nth-child(6) {
      min-width: 150px;
    }

    &:nth-child(7) {
      min-width: 180px;
    }

    &:not(:nth-child(6)):not(:nth-child(3)):not(:nth-child(5)):not(:nth-child(8)) {
      justify-content: center !important;
    }
  }
}

.telecom-tooltip-inner {
  background: $tooltip-bg;
  text-align: left;
  min-width: 246px;
  padding: 1rem;
}

.telecom-tooltip-arrow {
  &::before {
    border-bottom-color: $tooltip-bg !important;
  }
}

.delete-confirm-modal,
.submit-confirm-modal {
  max-width: 300px !important;
  margin-left: auto;
  margin-right: auto;

  .telecom-btn {
    min-width: auto;
    &.red {
      background: rgb(214, 24, 39) !important;
    }
  }

  .modal-header {
    border-bottom: 0px;
    padding-bottom: 0px;
  }

  .modal-body {
    padding-bottom: 3rem;
  }

  .delete-modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .modal-footer {
    border-top: 0px;
  }

  .confirm-text {
    text-align: center;
    font-weight: 600;
    color: $pagination-textColor;
    font-size: $button-fontSize;
  }

  .traceback-sub-title {
    border-bottom: 0px solid transparent;
    text-align: center;
  }

  p {
    text-align: center;
  }
}
@media (max-width: 320px) {
  .submit-confirm-modal,
  .delete-confirm-modal {
    margin-left: 3%;
    margin-right: 3%;
  }
}

.already-existing-modal {
  max-width: 400px !important;
  margin-left: auto;
  margin-right: auto;

  .telecom-btn {
    min-width: auto;
    &.red {
      background: rgb(214, 24, 39) !important;
    }
  }

  .modal-header {
    border-bottom: 0px;
    padding-bottom: 0px;
  }

  .modal-body {
    padding-bottom: 3rem;
  }

  .delete-modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .modal-footer {
    border-top: 0px;
  }

  .confirm-text {
    text-align: center;
    font-weight: 600;
    color: $pagination-textColor;
    font-size: $button-fontSize;
  }

  .traceback-sub-title {
    border-bottom: 0px solid transparent;
    text-align: center;
  }

  p {
    text-align: center;
  }
}

@media (max-width: 577px) {
  .already-existing-modal {
    max-width: 300px !important;
  }
}

@media (max-width: 320px) {
  .already-existing-modal {
    margin-left: 3%;
    margin-right: 3%;
  }
}

.tooltip.show {
  opacity: 1;
}

// disable div content
.is-disable {
  pointer-events: none;
  opacity: 0.5;
}

.stirShakenError {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: rgb(251, 238, 203);
  font-weight: bold;
}

.highlighted-background {
  background-color: #fcfcfc;
  border: 1px solid #e7e7e7;
  padding: 1.5rem;
  width: 100%;
}

.hop-dropdown {
  .popover {
    padding: 0.5rem 1rem !important;
    max-width: 100px;
    // transform: translate3d(680px, 62px, 0px) !important;
    border-top: 2px solid #163863;
    border-radius: 0;
  }

  .popover-body {
    list-style: none;
    .nav-link {
      font-family: 'Avenir-Medium', serif;
      font-weight: 800;
      padding: 0;
      color: #163863;
      text-align: right !important;
      margin-bottom: 0;
      text-transform: uppercase;
      cursor: pointer;

      &:nth-child(1) {
        margin-bottom: 1rem;
      }

      i {
        font-size: 10px;
        opacity: 0.4;
        color: green;
        padding-left: 8px;
        visibility: hidden;
      }
    }

    .active {
      i {
        visibility: visible;
      }
    }
  }
}

.is-valid {
  z-index: unset !important;
}

.is-invalid {
  z-index: unset !important;
}

.hop-navigation-column {
  @media (max-width: 577px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2%;
    .telecom-label {
      text-align: start !important;
      width: fit-content;
      margin-right: 3%;
      padding: 0%;
    }
  }
}
.hop-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (max-width: 577px) {
    flex-direction: row;
    justify-content: start;
    div {
      margin-right: 3%;
    }
  }
}

.upstream-comment {
  width: 40%;
  @media (max-width: 1024px) and (min-width: 578px) {
    width: 70%;
  }
  @media (max-width: 577px) {
    width: 150%;
  }
}
.customer-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3%;
  margin-left: 4%;
  margin-right: 2%;
}
.customer-input {
  margin-left: 2%;
  width: 30%;
  @media (max-width: 1024px) and (min-width: 578px) {
    width: 49%;
    margin-left: 1%;
    margin-bottom: 3%;
  }
  @media (max-width: 577px) {
    width: 90%;
    margin-left: 3%;
    margin-bottom: 4%;
  }
}
.customer-steps {
  margin-left: 2%;
  width: 62%;
  @media (max-width: 1024px) and (min-width: 578px) {
    width: 90%;
    margin-left: 0%;
    margin-bottom: 3%;
  }
  @media (max-width: 577px) {
    width: 100%;
    margin-left: 0%;
    margin-bottom: 4%;
  }
}
.dispute-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5%;
  width: 100%;
  @media (max-width: 1024px) and (min-width: 578px) {
    margin-left: 3%;
  }
  @media (max-width: 577px) {
    margin-left: 2%;
  }
}
.dispute-basis {
  display: flex;
  width: 70%;
  padding-left: 3%;
  @media (max-width: 1024px) and (min-width: 578px) {
    min-width: 70%;
    padding: 0%;
  }
  @media (max-width: 577px) {
    min-width: 100%;
    padding: 0%;
  }
}
.dispute-comment {
  width: 30%;
  @media (max-width: 1024px) and (min-width: 578px) {
    min-width: 50%;
  }
  @media (max-width: 577px) {
    width: 100%;
  }
}
.call-record-explanation {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-left: 5%;
  margin-top: 2%;
  @media (max-width: 577px) {
    width: 100%;
    margin-left: 0%;
  }
}
.hop-comments-form {
  display: flex;
  width: 100%;
  margin-top: 1%;
}
.hop-comment-input {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 4%;
  @media (max-width: 577px) {
    width: 100%;
    margin-top: 3%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
.hop-comment-upload {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 2%;
  @media (max-width: 577px) {
    width: 95%;
  }
}
.hop-comment-send {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
  @media (max-width: 1439px) and (min-width: 578px) {
    flex-wrap: wrap;
  }
  @media (max-width: 577px) {
    justify-content: start;
    flex-wrap: wrap;
    margin-left: 2%;
  }
}
